import axiosInstance from "./Api";

export default {
  async getHomeData() {
    return await axiosInstance().get(`/counselor/home-data`);
  },

  async getAssignedAgents(payload) {
    return axiosInstance().get(`/counselor/assigned-agents`, {
      params: payload,
    });
  },

  async getAreaFranchise(params) {
    return axiosInstance().get(`/counselor/area-franchise`, {
      params,
    });
  },

  async getMicroFranchise(params) {
    return axiosInstance().get(`/counselor/micro-franchise`, {
      params,
    });
  },

  async saveProfileExtraInfo(payload) {
    let response = await axiosInstance().post(
      `/counselor/save-application-limit/${payload.student_user_id}`,
      payload
    );

    return response;
  },

  async suggestCourseStudent(payload) {
    const { course_id, student_user_ids } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/suggest-course/${course_id}`,
      { student_user_ids }
    );

    return response;
  },

  async removeSuggestion(payload) {
    const { student_user_id, course_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/remove-suggestion/${course_id}`
    );

    return response;
  },

  async setStudentConverted(payload) {
    const { student_user_id, convert_status } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/converted/${convert_status}`
    );

    return response;
  },

  async saveAgentCommissionForApplication(payload) {
    const { student_user_id } = payload;

    let response = await axiosInstance().post(
      `/counselor/student/${student_user_id}/application-commission`,
      payload
    );

    return response;
  },

  async getStudentLeads(payload) {
    return await axiosInstance().get(`/counselor/student/leads`, {
      params: payload,
    });
  },

  async getFranchiseQuizResponse(payload) {
    const { agent_user_id } = payload;
    return await axiosInstance().get(
      `/counselor/franchise-quiz-responses/${agent_user_id}`,
      payload
    );
  },

  async getFranchiseList(payload) {
    return await axiosInstance().get(`/counselor/franchise-list`);
  },

  async getAllStudentSupportList(params) {
    return axiosInstance().get(`/counselor/student-support`, { params });
  },

  async getStudentTransactions(params) {
    return axiosInstance().get(`/counselor/student-transactions`, { params });
  },

  async saveStudentTransaction(payload) {
    return axiosInstance().post(`/counselor/student-transaction`, payload);
  },

  async getAllTransactions(params) {
    return await axiosInstance().get(`/counselor/transactions`, { params });
  },

  async createStudent(payload) {
    return axiosInstance().post(`/counselor/create-student`, payload);
  },

  async recommendation(payload) {
    return axiosInstance().get(`/counselor/recommendations-count`, {
      params: payload,
    });
  },

  async recommendationByUniversity(payload) {
    const { university_id } = payload;
    return axiosInstance().get(
      `/counselor/recommended-students/${university_id}`,
      payload
    );
  },
};
